import {isDateTbd} from '@wix/wix-events-commons-statics'
import {withTranslation} from '@wix/yoshi-flow-editor'
import {getFormMessages} from '../../../selectors/event'
import {getThankYouMessageState, isThankYouMessageYes} from '../../../selectors/navigation'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {isMember} from '../../../selectors/current-member-details'
import {showGroupCard} from '../../../selectors/groups'
import {getEventsMembersPageTitle} from '../../../selectors/events-members-page'
import {ThankYouMessagesRuntimeProps, ThankYouMessagesOwnProps} from './interfaces'
import {ThankYouMessages as Presentation} from './thank-you-messages'

const mapRuntime = (
  {state, actions: {thankYouPageLoaded, navigateToMainPage}}: DetailsPageAppProps,
  {t}: ThankYouMessagesOwnProps,
): ThankYouMessagesRuntimeProps => ({
  messages: getFormMessages(state.event, getThankYouMessageState(state)),
  dateTbd: isDateTbd(state.event),
  isMember: isMember(state),
  showGroup: showGroupCard(state) && isThankYouMessageYes(state),
  eventsMembersPageTitle: getEventsMembersPageTitle(state, t),
  thankYouPageLoaded,
  navigateToMainPage,
})

export const ThankYouMessages = withTranslation()(
  connect<ThankYouMessagesOwnProps, ThankYouMessagesRuntimeProps>(mapRuntime)(Presentation),
)
export * from './interfaces'
