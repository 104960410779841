import {isExternal} from '@wix/wix-events-commons-statics'
import {isEditor} from '../../../commons/selectors/environment'
import {State} from '../types'
import {hasGuestsGoing} from './event'
import {isMembersAvatarsEnabled} from './settings'

export const isMembersVisible = (state: State) =>
  !isExternal(state.event) && state.membersAreaEnabled && isMembersAvatarsEnabled(state.component.settings)

export const isMembersVisibleWithGuests = (state: State) =>
  isMembersVisible(state) && (hasGuestsGoing(state) || isEditor(state))
