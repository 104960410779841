import React from 'react'
import classNames from 'classnames'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import cs from '../../classnames.scss'
import s from './group-activity.scss'
import {GroupActivityProps} from './interfaces'

export const GroupActivity = ({className, ticketed, updatesCount, t}: GroupActivityProps) => (
  <div className={classNames(s.root, cs.evTextFont, cs.evTextColor, className)} data-hook={DH.GROUPS_ACTIVITY}>
    <div className={s.text}>{t(ticketed ? 'groups.ticketedExplanation' : 'groups.rsvpExplanation')}</div>
    {updatesCount !== 0 ? (
      <div className={s.activity}>
        {updatesCount === 1
          ? t('groups.activity.singular')
          : t('groups.activity.plural', {updatesCount: updatesCount ?? '-'})}
      </div>
    ) : null}
  </div>
)
